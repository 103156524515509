.taSprite_traffic_light_red,
.taSprite_traffic_light_green,
.taSprite_traffic_light_gray {
  background-image: none !important;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
}
.taSprite_traffic_light_yellow {
  background-position: -42.2rem -3.2rem;
  width: 15px;
  height: 15px;
  margin: 0 auto;
}

.taSprite_inputNumberArrowDown {
  width: 22px;
  height: 13px;
  background-position: -15.9rem -0.3rem;
  display: block;
  background-size: auto !important;
  margin: 0 auto !important;
}
.taSprite_inputNumberArrowUp {
  width: 16px !important;
  height: 13px;
  background-position: -18.6rem -0.2rem;
  display: block;
  background-size: auto !important;
  margin: 0 auto !important;
}

.taSprite_parfx_large {
  background-position: 0 -30.7rem;
}
.taSprite_icon_closethick {
  background-position: -134.4rem 0;
}

.taSprite_nomura_large {
  background-position: -14px -748px;
  width: 974px;
  height: 255px;
  margin-left: -213px;
  margin-bottom: 0.7rem;
}
